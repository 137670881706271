$height: 56px;

.wrapper {
  flex-shrink: 0;
  position: relative;
  height: $height;

  display: flex;
  justify-content: center;
  align-items: center;

  &__logo {
    position: absolute;
    margin-top: 6px;
    width: 150px;
    max-height: $height;
    text-decoration: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1;

    img {
      margin-top: -10px;
      max-width: 100%;
    }

    &-text {
      color: #fff;
      font-size: 11px;
      font-weight: 600;
      text-transform: uppercase;
    }
  }

  &__header {
    padding: 0.4rem;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__signin {
    padding: 8px 12px;
    background: var(--color-primary);
    color: #fff;
    border-radius: var(--border-radius);
    border: 2px solid var(--color-secondary);
    font-size: 14px;
    text-decoration: none;
    transition: var(--transition);

    &:hover {
      background: var(--color-primary-darken-1);
      color: #fff;
    }

    &:active {
      background: var(--color-primary-darken-2);
      color: #fff;
    }
  }
}

.lang {
  display: flex;
  column-gap: 8px;

  &__item {
    font-weight: bold;
    font-size: 14px;
    cursor: pointer;
    display: flex;
    align-items: center;
    column-gap: 4px;

    &--active {
      color: var(--color-accent);
      border-bottom: 2px solid var(--color-accent);
      pointer-events: none;
    }

    img {
      width: 22px;
      height: 14px;
      border-radius: 2px;
    }
  }
}

.avatar {
  position: relative;
  font-size: 14px;

  &__menu {
    overflow: hidden;
    position: absolute;
    right: 0;
    width: 180px;
    margin-top: 4px;
    padding: 0 !important;
    z-index: 100;
  }

  &__user {
    width: 36px;
    height: 36px;
    background: var(--color-primary);
    border: 2px solid var(--color-secondary);
    border-radius: 50%;
    transition: var(--transition);
    cursor: pointer;

    img {
      max-width: 100%;
      filter: invert(100%);
    }

    &:hover {
      filter: brightness(80%);
    }
  }

  &__name {
    padding: 8px;
    text-align: center;
    word-break: break-all;
  }

  &__email {
    padding: 8px;
    padding-top: 0;
    text-align: center;
    word-break: break-all;

    font-size: 12px;
    opacity: 0.7;
  }

  &__btn {
    padding: 8px;
    border-top: 2px solid var(--color-border);
    text-align: center;
    transition: var(--transition);
    cursor: pointer;

    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 8px;

    &:hover {
      background: var(--color-primary-darken-1);
    }

    &:active {
      background: var(--color-primary-darken-2);
    }

    img {
      max-width: 12px;
      filter: invert(100%);
    }
  }
}

@media (max-width: 980px) {
  $height: 50px;

  .wrapper {
    height: $height;

    &__logo {
      max-height: $height;
      width: 120px;

      &-text {
        font-size: 10px;
        margin-left: 6px;
      }
    }

    &__signin {
      font-size: 12px;
    }
  }
}