$border-color: #286bcd;
$border-size: 8px;
$footer-height: 100px;

.footer {
  position: relative;
  flex-shrink: 0;
  height: $footer-height;
  margin-top: calc(2% + 16px);
  background: #0c3592;
  border-top: $border-size solid $border-color;

  display: flex;
  justify-content: center;
  align-items: flex-end;

  &__btn {
    height: 100%;
    width: 160px;
    opacity: 0.5;
    cursor: pointer;

    display: flex;
    justify-content: center;
    align-items: center;

    img {
      max-width: 48px;
      filter: invert(100%);
    }

    &--active {
      opacity: 1;
    }
  }

  &__full {
    position: absolute;
    top: 0;
    right: 10px;
    transform: translateY(calc(-100% - 20px));
    
    padding: 12px;
    background: black;
    border-radius: 50%;
    opacity: 0.3;
    transition: var(--transition);
    -webkit-tap-highlight-color: transparent;
    cursor: pointer;

    &:hover {
      opacity: 0.5;
    }

    img {
      vertical-align: bottom;
      width: 32px;
      height: 32px;
      filter: invert(100%)
    }
  }
}

.home {
  position: relative;
  width: 160px;
  height: 140%;
  background: rgb(96, 159, 245);
  background: linear-gradient(180deg, rgba(96, 159, 245, 1) 0%, rgba(40, 100, 195, 1) 100%);
  border: $border-size solid $border-color;
  border-bottom: none;
  border-radius: 36px 36px 0 0;
  cursor: pointer;

  &__body {
    position: absolute;
    left: 50%;
    width: 120px;
    height: 58px;
    transform: translateX(-50%) translateY(30%);
    background: linear-gradient(180deg, #b2d8f9 0%, #6ea3f5 100%);
    border-radius: 16px;
    box-shadow: 0 4px 0 #2b62b1;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__icon {
    width: 58px;
    height: 64px;
    background: #91e6dd;
    box-shadow: 0 4px 0 #4aa5c0;
    border-radius: 18px;
    z-index: 1;

    display: flex;
    justify-content: center;
    align-items: center;

    img {
      vertical-align: bottom;
      max-width: 36px;
      filter: invert(80%) drop-shadow(1px 1px 0 #6d6d6d) drop-shadow(-1px -1px 0 #6d6d6d);

      .home--active & {
        filter: invert(100%) drop-shadow(1px 1px 0 #438889) drop-shadow(-1px -1px 0 #438889);
      }
    }
  }
}

@media (max-width: 980px) {
  $footer-height: 80px;

  .footer {
    height: $footer-height;

    &__btn {
      img {
        max-width: 40px;
        filter: invert(100%);
      }
    }
  }

  .home {
    &__body {
      width: 90px;
    }
  }
}