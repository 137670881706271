.tooltip {
  max-width: 100%;
  width: max-content;

  &__text {
    position: fixed;
    transform: translateY(calc(-100% - 14px));
    z-index: 10;

    padding: 8px;
    max-width: min(240px, 100%);
    background: var(--color-primary);
    border-radius: var(--border-radius);
    box-shadow: var(--box-shadow);
    line-break: anywhere;
    transition: opacity var(--transition);

    &--hidden {
      opacity: 0;
      pointer-events: none;
    }
    
    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%) translateY(calc(50% + 2px)) rotateZ(45deg);
      width: 16px;
      height: 16px;
      background: var(--color-primary);
      border-bottom: 4px solid var(--color-secondary);
      border-right: 4px solid var(--color-secondary);
    }
  }
}