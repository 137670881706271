$color-secondary: #3d7ac4;

.wrapper {
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
}

.round {
  overflow-y: auto;
  width: min(420px, 96%);
  margin: 2% auto;
  padding: 24px;
  background: var(--color-primary);
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
  border: 4px solid $color-secondary;

  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.25);
    border-radius: 4px;
  }

  &::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.05);
    border-radius: 4px;
  }
}

.player {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__sound {
    font-size: 18px;
    font-weight: bold;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__icon {
    font-size: 52px;
  }

  &__text {
    margin-top: 24px;
    text-align: center;
  }

  &__btn {
    margin: 32px 0;
    width: 140px;
    height: 140px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    img {
      vertical-align: bottom;
      width: 100%;
    }

    &--play {
      img {
        width: 100%;
      }
    }

    &--stop {
      img {
        width: 40%;
      }
    }
  }
}

.info {
  padding: 24px;
  background: $color-secondary;
  border-radius: var(--border-radius);
  font-weight: bold;

  display: flex;
  flex-direction: column;
  align-items: center;

  &__name {
    font-size: 20px;
  }

  &__date {
    margin-top: 4px;
    font-size: 14px;
    opacity: 0.5;
  }

  &__score {
    font-size: 48px;
    margin: 32px 0;
  }

  &__btn {
    margin-top: 24px;
    margin-inline: auto;
    height: 2.5em;
    width: min(200px, 100%);
    color: white;
    font-size: 28px;
    font-weight: bolder;
    text-transform: uppercase;
    text-align: center;
    text-shadow: 2px 2px rgb(98, 98, 98);
    border-radius: 40px;
    border: 5px solid rgba(219, 219, 219, 0.5);
    background: linear-gradient(0deg, rgb(226, 201, 55) 0%, rgb(239, 175, 26) 50%, rgb(251, 153, 0) 100%);
    background-clip: padding-box;
    text-decoration: none;

    display: flex;
    align-items: center;
    justify-content: center;
  }
}