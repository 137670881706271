.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;

  &__title {
    font-size: 30px;
    font-weight: bolder;
    z-index: 1;
  }

  &__background {
    position: absolute;
  }
}