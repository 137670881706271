.image {
    &--expanded {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;

        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 100;

        background: rgba(0, 0, 0, 0.75);
        padding: 4%;

        img {
            max-height: 100% !important;
            max-width: 100% !important;
        }
    }

    img {
        vertical-align: bottom;
        max-width: min(var(--width), 100%);
        cursor: pointer;
    }
}