.header {
  display: flex;
  align-items: center;
  column-gap: 8px;

  &__back {
    z-index: 1;

    img {
      margin-left: -3px;
      max-height: 16px;
      filter: invert(100%);
    }
  }
}

.player {
  padding: 0.5% !important;
  padding-left: 3% !important;
  background: var(--color-primary);
  border: 2px solid var(--color-secondary);
  border-radius: 100px !important;
  z-index: 1;

  flex-grow: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 2%;

  &__emoji {
    font-size: 20px;
  }

  &__info {
    flex-grow: 1;
    font-weight: bold;

    &-text {
      font-size: 12px;
      opacity: 0.5;
    }

    &-sound {
      font-size: 18px;
    }
  }

  &__btn {
    width: 42px;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    img {
      vertical-align: bottom;
      width: 100%;
    }

    &--play {
      img {
        width: 100%;
      }
    }

    &--stop {
      img {
        width: 40%;
      }
    }
  }
}