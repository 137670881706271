.wrapper {
  height: 100%;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.lobby {
  width: min(360px, 100%);
  height: 385px;
  background-image: url(../../images/name_card.svg);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__logo {
    margin-top: -16%;
    height: 46%;
  }

  &__title {
    margin: 0;
    text-shadow: 4px 4px rgb(98, 98, 98);
    font-size: 50px;
    text-align: center;
  }

  &__buttons {
    margin-top: 20px;
    width: min(400px, 80%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    row-gap: 12px;
  }
}


@media (max-width: 980px) {
  .lobby {
    &__title {
      font-size: 46px;
    }
  }
}