.wrapper__title {
  margin: 6px 0;
  font-weight: bolder;
  font-size: 46px;
  text-shadow: 3px 3px rgba(0,0,0,0.5);
  text-transform: uppercase;
  color: rgb(255,176,0);
}

.wrapper__score {
  font-weight: bolder;
  font-size: 38px;
  color: #1750a7;
  text-shadow: 2px 2px rgba(0,0,0,0.5);
  margin-top: 12px
}

.wrapper__button {
  margin-top: 28px;
  height: 60px;
  width: 180px;
  font-size: 20px;
  font-weight: bolder;
  text-transform: uppercase;
  color: white;
  background-color: rgb(12 53 146);
  border-radius: 30px;
  border: 3px solid rgba(12, 53, 146, 0.5);
  background-clip: padding-box;
}