.wrapper {
  display: flex;
  justify-content: center;
  position: relative;

  &__popup {
    position: absolute;
    top: 0;
    transform: translateY(calc(-100% - 16px));

    padding: 12px;
    padding-bottom: 8px;
    background: #fff;
    border-radius: var(--border-radius);
    box-shadow: var(--box-shadow);

    display: flex;
    column-gap: 12px;

    .wrapper--left & {
      right: 0;
    }

    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%) translateY(50%) rotateZ(45deg);
      width: 16px;
      height: 16px;
      background: #fff;

      .wrapper--left & {
        left: 100%;
        transform: translateX(calc(-100% - 16px)) translateY(50%) rotateZ(45deg);
      }
    }
  }

  &__btn {
    cursor: pointer;
    
    img {
      width: 32px;
      height: 32px;
      vertical-align: bottom;
    }
  }
}