$spacing: 12px;

.wrapper {
  margin-inline: auto;
  padding: 0 $spacing;
  width: min(100%, 960px);

  &__title {
    color: #fff;
    text-align: center;
  }

  &__categories {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: $spacing;
    row-gap: $spacing;
  }
}

.category {
  overflow: hidden;
  border: 3px solid var(--color);
  border-radius: 16px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);

  &__header {
    padding: 14px;
    background: var(--color);
    text-align: center;
  }

  &__items {
    display: flex;
    flex-direction: column;
  }

  &__text {
    margin-left: 8px;
    color: #fff;
    font-weight: bold;
  }
}

$color: #275fae;

.item {
  padding: 14px;
  background: $color;
  text-align: center;
  transition: .3s;
  cursor: pointer;

  &:not(:first-child) {
    border-top: 1px solid darken($color, 6%);
  }

  &:hover {
    background: darken($color, 6%);
  }

  &__text {
    margin-left: 8px;
    color: #fff;
  }
}

@media (max-width: 768px) {
  .wrapper {
    &__categories {
      grid-template-columns: 1fr;
    }
  }
}