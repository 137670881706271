.progress {
  position: relative;
  height: var(--height);
  box-shadow: inset 0 0 0 2.5px var(--color);
  border-radius: 16px;
  display: flex;
  align-items: center;
}

.progress__bar {
  background: var(--color);
  width: var(--width);
  height: 100%;
  border-radius: 16px;
  transition: .5s;
}

.progress__text {
  position: absolute;
  width: 100%;
  color: #fff;
  font-weight: bold;
  text-shadow: 0px 0px 4px rgb(0 0 0);
}

.label {
  position: absolute;
  top: 0;
  left: var(--position-x);
  font-size: 14px;
  font-weight: bold;
}

.label__text {
  width: max-content;
  position: absolute;
  color: var(--color);
  transform: translate(var(--translate-x), -130%);
}