* {
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
}

body,
html {
  margin: 0;
  padding: 0;
}

button {
  cursor: pointer;
}

$color-accent: #faba24;
$color-primary: #275ca6;

:root {
  --color-primary-lighten-2: #{lighten($color-primary, 12%)};
  --color-primary-lighten-1: #{lighten($color-primary, 6%)};
  --color-primary: #{$color-primary};
  --color-primary-darken-1: #{darken($color-primary, 8%)};
  --color-primary-darken-2: #{darken($color-primary, 16%)};
  --color-secondary: #3d7ac4;
  --color-accent: #{$color-accent};
  --color-margin: #0c3592;
  --color-border: #3d7ac4;
  --border-radius: 16px;
  --box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  --transition: .3s;
}

#root {
  overflow: hidden;
  height: 100vh;
  min-height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  background-color: #315fb0;
  color: #fff;
  background-image: url(./images/background.svg);
}

.main {
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.25);
    border-radius: 4px;
  }
  &::-webkit-scrollbar-track {
      background: rgba(255, 255, 255, 0.05);
      border-radius: 4px;
  }
}

.app-container {
  width: min(600px, 100%);
  padding: .75rem;
  margin-inline: auto;
}

.app-center {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.app-card {
  padding: .75rem;
  background: var(--color-primary);
  border-radius: var(--border-radius);
  border: 4px solid var(--color-secondary);
  box-shadow: var(--box-shadow);
}

.app-title {
  text-align: center;
}

a {
  color: $color-accent;
  cursor: pointer;

  &:hover {
    color: darken($color-accent, 20%);
  }
}