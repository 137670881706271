.turns {
  width: 100%;

  &__header {
    display: flex;
    justify-content: space-between;
  }

  &__chip {
    flex-shrink: 0;
    width: min(160px, 50%);
    height: 70px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    color: white;
    font-size: 20px;
    font-weight: bold;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__round {
    background-image: url(../../../images/turns.png);

    span {
      transform: translateX(6px);
    }
  }

  &__coins {
    background-image: url(../../../images/coins.png);

    span {
      transform: translateX(20px);
    }
  }

  &__name {
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center;

    background-color: white;
    width: 100%;
    min-height: 56px;
    margin-top: 12px;

    color: rgb(84, 175, 165);
    font-size: 20px;
    font-weight: bold;
    text-shadow: 1px 1px rgb(98, 98, 98);

    border-radius: 20px;
    border: 2px solid rgba(98, 98, 98, 0.5);
  }
}

.spin-wheel {
  overflow: hidden;
  flex-grow: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__wrapper {
    height: 100%;
    width: 100%;
    position: relative;
    animation-name: wheel-bounce;
    animation-duration: 1s;
    display: flex;
    align-items: center;
    justify-content: center;

    &>div {
      width: 100%;
      height: 100%;
    }

    canvas {
      max-width: 95%;
      max-height: 95%;
      width: auto;
      height: auto;
    }
  }

  &__button {
    z-index: 9;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-transform: uppercase;

    background: rgb(251, 251, 251);
    background: radial-gradient(circle, rgba(251, 251, 251, 1) 0%, rgba(228, 228, 228, 1) 50%, rgba(219, 219, 219, 1) 100%);
    border: 2px solid rgb(98, 98, 98);
    border-radius: 50%;
    width: 85px;
    height: 85px;

    color: rgb(98, 98, 98);
    font-size: 18px;
    font-weight: bolder;
  }

  &__button:focus {
    outline: none;
  }

  &__arrow {
    z-index: 10;
    position: absolute;
    top: 0;
    left: calc(100% - 28.6px);
    border: 3px solid rgb(98, 98, 98);
    background: rgb(224, 224, 224);
    background: linear-gradient(-45deg, rgba(219, 219, 219, 1) 20%, rgba(228, 228, 228, 1) 60%, rgba(240, 240, 240, 1) 100%);
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 15px;
    transform: rotate(-85deg);
  }

  &__selected-category {
    position: absolute;
    z-index: 10;
    top: 0;
    width: 270px;
    height: 180px;
    background-image: url(../../../images/selected_category.svg);

    font-size: 30px;
    font-weight: bolder;
    text-transform: uppercase;
    text-shadow: 1px 1px rgba(0, 0, 0, 0.5);
    text-align: center;
    padding-top: 110px;

    animation-name: bounce;
    animation-duration: 1s;
  }
}

@keyframes wheel-bounce {
  0% {
    opacity: 0.5;
    transform: scale(0.8);
  }

  50% {
    opacity: 1;
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes bounce {
  0% {
    opacity: 0.5;
    transform: scale(0.5);
  }

  50% {
    opacity: 1;
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
  }
}