.wrapper {
  margin-inline: auto;
  width: min(100%, 600px);

  &__title {
    color: #fff;
    text-align: center;
  }
}

.tabs {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-bottom: 16px;

  &__btn {
    padding: 8px;
    color: rgba(255, 255, 255, 0.5);
    text-align: center;
    font-weight: bold;
    cursor: pointer;
  }

  &__btn--active {
    position: relative;
    color: #fff;

    &::after {
      content: '';
      position: absolute;
      bottom: -2px;
      left: 50%;
      width: 6px;
      height: 6px;
      background: #fff;
      border-radius: 50%;
    }
  }
}

.filter {
  padding: 24px 3.5%;
  color: #fff;
  font-weight: bold;
  display: flex;
  align-items: center;

  select {
    width: 100%;
    margin-left: 16px;
    padding: 8px 12px;
    border: 2px solid var(--color-secondary);
    background: var(--color-primary);
    color: #fff;
    border-radius: 100px;
    cursor: pointer;
  }
}

.item {
  &__btn {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    img {
      vertical-align: bottom;
      width: 100%;
    }

    &--play {
      img {
        width: 100%;
      }
    }

    &--stop {
      img {
        width: 50%;
      }
    }

    &--share {
      img {
        width: 100%;
        filter: invert(100%);
      }
    }
  }
}