.game-screen {
  position: relative;
  width: min(500px, 100%);
  height: 100%;
  padding: 10px;
  margin-inline: auto;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.record {
  flex-shrink: 0;
  position: relative;
  transform: translateY(-4vh);
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  margin: 24px auto 36px;
  width: min(360px, 100%);
  height: 484px;
  background-image: url(../../images/container.svg);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  padding-top: 200px;

  text-align: center;
  color: rgb(98, 98, 98);
}

.record__class {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  text-align: center;
  text-transform: uppercase;
  font-weight: bolder;
  font-size: 50px;
  text-shadow: 1px 1px rgba(0, 0, 0, 0.5);
  margin: 15px 0 70px;
}

.record__predicted-class {
  text-transform: uppercase;
  font-weight: bolder;
  font-size: 32px;
  text-shadow: 1px 1px rgba(0, 0, 0, 0.5);
  margin-bottom: 8px
}

.record__title {
  position: absolute;
  top: 96px;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  font-weight: bolder;
  font-size: 80px;
  text-shadow: 3px 3px rgba(0, 0, 0, 0.5);
  text-transform: uppercase;
  color: rgb(255, 176, 0);
}

.record__score-container {
  font-size: 22px;
}

.record__score {
  font-weight: bolder;
  font-size: 60px;
  text-shadow: 2px 2px rgba(0, 0, 0, 0.5);
  color: rgb(111, 191, 39);
}

.record__score--wrong {
  color: rgb(224, 65, 64);
}

.record__bar {
  margin-top: 36px;
}

.record__button {
  position: absolute;
  bottom: -40px;
  left: 50%;
  transform: translateX(-50%);

  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: white;
  border: 5px solid rgba(98, 98, 98, 0.5);
  background-clip: padding-box;
}

.record__button img {
  max-width: 36px;
}

.record__button--active {
  background-color: rgb(98, 98, 98);
}

.record__loading {
  font-weight: bolder;
  font-size: 40px;
  text-shadow: 2px 2px rgba(0, 0, 0, 0.5);
  text-transform: uppercase;
  color: rgb(255, 176, 0);
}

.record__buttons {
  margin-top: 12px;
  flex-shrink: 0;
  height: 64px;
  display: flex;
  column-gap: 8px;
}

.record__finish-round {
  height: 60px;
  width: 180px;
  font-size: 20px;
  font-weight: bolder;
  text-transform: uppercase;
  color: white;
  background-color: rgb(12 53 146);
  border-radius: 30px;
  border: 3px solid rgba(12, 53, 146, 0.5);
  background-clip: padding-box;
  margin-bottom: -30px;
}

.record__finish-round--retry {
  background-color: rgb(98, 98, 98);
  border-color: rgba(98, 98, 98, 0.5);
}

.record__finish-round--share {
  background-color: #6fbf27;
  border-color: rgba(98, 98, 98, 0.5);
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 10px;
}

.record__finish-round--share img {
  max-width: 32px;
  margin-left: -10px;
  filter: invert(100%)
}

.recording_animation {
  width: max-content;
  margin-inline: auto;
  display: flex;
  padding: 15px;
  border-radius: 15px;
  background-color: rgb(98, 98, 98);
}

.playback {
  margin-top: 12px;
  display: flex;
  justify-content: center;
}

.playback__button {
  width: 150px;
  height: 60px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(255, 176, 0);
  border: 2px solid transparent;
  transition: var(--transition);
}

.playback__button--disabled {
  pointer-events: none;
  opacity: 0.6;
}

.playback__icon {
  width: 54px;
  height: 54px;
}