.button {
  height: 56px;
  min-width: 56px;
  padding: 0 16px;
  color: white;
  font-size: 24px;
  font-weight: bolder;
  text-transform: uppercase;
  text-shadow: 2px 2px rgb(98, 98, 98);
  border-radius: 40px;
  border: 5px solid rgba(219, 219, 219, 0.5);
  background: linear-gradient(0deg, rgb(226, 201, 55) 0%, rgb(239, 175, 26) 50%, rgb(251, 153, 0) 100%);

  &:hover {
    filter: brightness(0.95);
  }

  &:active {
    filter: brightness(0.9);
  }

  &--icon {
    padding: 0 !important;
  }

  &--small {
    height: 46px;
    min-width: 46px;
    padding: 0 12px;
    font-size: 20px;
  }

  &--disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  &--block {
    width: 100%;
  }
}