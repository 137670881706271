$spacing: 24px;

.wrapper {
  padding: 16px 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form {
  width: min(360px, 100%);
  padding: 24px !important;

  h2 {
    margin: 0;
  }

  form {
    display: flex;
    flex-direction: column;
  }

  input {
    margin-top: $spacing;
    padding: 12px 18px;
    border-radius: 40px;
    border: none
  }

  button {
    margin-top: $spacing;
  }

  &__text {
    margin-top: $spacing;
    font-size: 13px;
    text-align: center;
  }

  &__info {
    display: block;
    margin-top: $spacing;
    text-align: center;
    cursor: pointer;
  }

  &__error {
    padding: 9px 18px;
    margin-top: $spacing;
    background: #e98100;
    border-radius: 40px;
  }
}