.ranking {
  overflow: hidden;
  padding: 0 !important;

  &__loading {
    padding: 40px 0;
    color: #fff;
    text-align: center;
    font-weight: bold;
  }
}

.item {
  display: flex;
  column-gap: 3%;
  align-items: center;

  height: 80px;
  padding: 0 3.5%;
  color: #fff;
  font-weight: bold;

  &:not(:first-child) {
    border-top: 1px solid #ffffff14;
  }

  &--me {
    margin: 4px;
    background: rgb(36 250 62 / 62%);
    border: 4px solid #58c883 !important;
    border-radius: var(--border-radius);
  }

  &__position {
    flex-shrink: 0;
    width: 32px;
    font-size: 18px;
    text-align: center;

    img {
      vertical-align: bottom;
      max-width: 100%;
    }
  }

  &__info {
    overflow: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  &__name {
    display: inline-block;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__date {
    margin-top: 2px;
    color: rgba(255, 255, 255, 0.5);
    font-size: 12px;
  }

  &__score {
    width: 32px;
    font-size: 18px;
  }

  &__units {
    margin-left: 4px;
    font-size: 12px;
    color: rgba(255, 255, 255, 0.5);
  }
}

.empty {
  margin: 32px 0;
  text-align: center;

  &__btn {
    font-weight: bold;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}