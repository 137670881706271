.wrapper {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &__body {
        margin-inline: auto;
        transform: translateY(-5vh);
    }
}

.record {
    height: 100%;

    background-image: url(../../../images/container.svg);
    background-repeat: no-repeat;
    background-position: center;

    display: grid;
    grid-template-rows: 0.4fr 1fr;

    &__body {
        grid-row-start: 2;
        position: relative;

        padding: 10% 0;
        color: #626262;
        text-align: center;
        word-break: break-word;

        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__title {
        position: absolute;
        top: -14%;

        color: rgb(255, 176, 0);
        font-weight: bolder;
        font-size: 72px;
        text-shadow: 3px 3px rgba(0, 0, 0, 0.5);
        text-transform: uppercase;
    }

    &__class {
        padding: 0 4%;
        flex-grow: 1;
        display: flex;
        align-items: center;

        text-transform: uppercase;
        font-weight: bolder;
        font-size: 46px;
        text-shadow: 1px 1px rgba(0, 0, 0, 0.5);
    }

    &__button {
        position: absolute;
        bottom: 0;
        transform: translateY(calc(50% - 10px));

        width: 100px;
        height: 100px;
        border-radius: 50%;
        background-color: white;
        border: 5px solid rgba(98, 98, 98, 0.5);
        background-clip: padding-box;

        img {
            max-width: 36px;
        }

        &--active {
            background-color: rgb(98, 98, 98);
        }
    }

    &__error {
        position: absolute;
        bottom: 64px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 1;

        width: 240px;
        color: #fff;
        font-size: 14px;
        cursor: initial;
    }

    &__animation {
        position: absolute;
        bottom: -120px;

        width: max-content;
        margin-inline: auto;
        display: flex;
        padding: 15px;
        border-radius: 15px;
        background-color: rgb(98, 98, 98);
    }

    &__loading {
        font-weight: bolder;
        font-size: 40px;
        text-shadow: 2px 2px rgba(0, 0, 0, 0.5);
        text-transform: uppercase;
        color: rgb(255, 176, 0);
    }

    &__bar {
        width: 90%;
    }
}


@media (max-width: 980px) {
    .record {
        &__class {
            font-size: 42px;
        }
    }
}