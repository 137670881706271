.wrapper {
    width: min(var(--width), 100%);

    &__1 {
        position: relative;
        padding-top: var(--ratio);
    }

    &__2 {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }
}